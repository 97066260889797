<script setup lang="ts">
import type { NavigationItem } from "@/types";

interface Props {
  item: NavigationItem;
}

defineProps<Props>();

const route = useRoute();

const isCurrentRoute = (item: NavigationItem) => {
  const [routeSegment] = route.path.replace(/^\/dashboard\//, "").split("/");
  const [hrefSegment] = item.href.replace(/^\/dashboard\//, "").split("/");
  return routeSegment === hrefSegment;
};
</script>

<template>
  <li class="relative">
    <NuxtLink
      :to="item.href"
      class="group flex rounded-md p-2 text-sm font-medium leading-6 group text-dark-gray dark:text-white"
      :class="[
        isCurrentRoute(item)
          ? 'bg-light-gray dark:bg-dark-gray text-primary dark:text-white'
          : 'hover:bg-white-gray dark:hover:bg-dark-gray',
      ]"
    >
      <CircleBadge
        v-if="item.notification"
        class="absolute top-0 left-0 group-hover:translate-x-1 transition-transform z-10"
        type="danger"
        >{{ item.notification }}</CircleBadge
      >
      <div class="group-hover:translate-x-1 transition-transform flex items-center gap-x-3">
        <Icon
          :name="item.icon"
          class="h-6 w-6 shrink-0"
          :class="[isCurrentRoute(item) ? 'text-primary' : 'text-medium-gray']"
          aria-hidden="true"
        />
        {{ item.name }}
      </div>
    </NuxtLink>
  </li>
</template>
